import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faGithub,
  faLinkedin,
  faMastodon,
} from '@fortawesome/free-brands-svg-icons';

const Container = styled.footer`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95vw;
  height: 4rem;
  bottom: 0;
  background-color: ${(props) => props.theme.background};
  transition: background-color ${(props) => props.theme.colorTransition};
  z-index: 5;
`;
const Content = styled.div`
  margin-left: auto;
  > a:not(:last-child) {
    margin-right: 1rem;
  }
`;
const Link = styled.a`
  color: ${(props) => props.theme.text};
  text-decoration: none;
  :hover {
    color: ${(props) => props.hoverColor};
  }
`;

const Footer = () => (
  <Container>
    <Content>
      <Link href="https://github.com/JordanDChappell" hoverColor="#B1B5BA">
        <FontAwesomeIcon icon={faGithub} />
      </Link>
      <Link href="https://mstdn.social/@JordanDChappell" hoverColor="#6364FF">
        <FontAwesomeIcon icon={faMastodon} />
      </Link>
      <Link
        href="https://linkedin.com/in/jordan-chappell-380486153/"
        hoverColor="#3E67BB"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </Link>
      <Link href="mailto:me@jordanchappell.com" hoverColor="#B1B5BA">
        <FontAwesomeIcon icon={faEnvelope} />
      </Link>
    </Content>
  </Container>
);

export default Footer;
